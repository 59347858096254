import React, { useState, useRef, useEffect } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Typography,
  FormHelperText,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import hooks from "./hooks";
import CameraModal from "./CameraModal";

const UploadAndPreview = ({
  options,
  context,
  requestData,
  showFormErrors,
  onReadyToSubmit,
}) => {
  const {
    label,
    description,
    accepted,
    minFiles,
    acceptedFiles,
    readOnly,
    disableCamera,
    immovableAfter,
    maxFiles,
    minFileSize,
    maxFileSize,
  } = options;
  const [upload, setUpload] = useState({});
  const [validity, setValidity] = useState({
    minFiles: false,
    maxFiles: true,
    minFileSize: true,
    maxFileSize: true,
    acceptedFiles: true,
  });
  const fileInputRef = useRef();
  const [dragging, setDragging] = useState(false);
  const dragCounter = useRef(0);
  const [openCameraModal, setOpenCameraModal] = useState(false);

  const { foreign_id, ...rest } = requestData;
  const requestDataInfo = foreign_id ? requestData : rest;

  useEffect(() => {
    if (requestDataInfo?.foreign_id?.length === 0) {
      delete requestDataInfo.foreign_id;
    }
  }, [upload]);

  useEffect(() => {
    if (minFiles === 0) {
      onReadyToSubmit && onReadyToSubmit(true);
    } else {
      onReadyToSubmit && onReadyToSubmit(false);
    }
  }, []);

  const handleFileChange = (event) => {
    processFiles(event.target.files);
  };

  const handleCameraFileChange = (imageFile) => {
    processFiles([imageFile]);
  };

  const handleSubmit = () => {
    fileInputRef.current.click();
  };

  const getFileTypes = (extensions) => {
    return extensions.map((ext) => ext.split("/")[1].toUpperCase()).join(", ");
  };

  const processFiles = (files) => {
    const tooMany =
      (upload?.files?.length || 0) >= maxFiles || files.length > maxFiles;
    if (tooMany) {
      setValidity((prevValidity) => ({ ...prevValidity, maxFiles: false }));
      return;
    }

    let tempValidity = {
      ...validity,
      maxFiles: true,
      minFiles:
        (isNaN(upload?.files?.length)
          ? files.length
          : upload.files.length + files.length) >= minFiles,
    };
    setValidity(tempValidity);

    const filesArray = Array.from(files)
      .filter((file) => {
        return validateFile(file);
      })
      .map((file) => {
        return file;
      });

    if (tempValidity.minFiles && tempValidity.maxFiles) {
      createOrGetUpload(filesArray);
    }
  };

  const createUpload = async () => {
    const queryDetails = {
      query: `mutation($item: NewUpload!) {
          upload: createUpload(item: $item) {
            id
            files {
              id
              uploaded_at
              original_filename
              previews { 
                url 
              } 
            } 
          } 
        }`,
      variables: { item: requestDataInfo },
    };

    try {
      const response = await hooks.gql(queryDetails, context);
      return response;
    } catch (error) {
      console.error("Error creating upload: ", error);
    }
  };

  const getUpload = async (id) => {
    const queryDetails = {
      query: `
        query($id: ID!) { 
          upload(id: $id) { 
            id 
            extra 
            files { 
              id 
              url 
              uploaded_at 
              original_filename 
              previews { 
                url 
              } 
            } 
          } 
        }`,
      variables: { id },
    };

    try {
      const response = await hooks.gql(queryDetails, context);
      return response.data;
    } catch (error) {
      console.error("Error getting upload: ", error);
    }
  };

  const putFile = async (upload, file) => {
    const queryDetails = {
      query: `
          mutation($upload_id: ID!, $item: NewFile!) { 
            file: updateUploadNewFile(upload_id: $upload_id, item: $item) { 
              id 
              put_content_url 
              original_filename 
            } 
          }`,
      variables: {
        upload_id: upload.id,
        item: {
          name: file.name,
          type: file.type,
          size: file.size,
        },
      },
    };
    try {
      const newFileRes = await hooks.gql(queryDetails, context);
      // Pre-Upload to UI...
      setUpload((prevUpload) => ({
        ...prevUpload,
        files: [
          ...(prevUpload?.files || []),
          { ...newFileRes.data.file, loading: true },
        ],
      }));

      // Upload file
      await fetch(newFileRes.data.file.put_content_url, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: file,
      });

      const uploadRes = await getUpload(upload.id);
      let tempValue = context.pick("value");
      /* eslint-disable @typescript-eslint/no-unused-vars */
      let { extra, ...tempUpload } = uploadRes.upload;
      /* eslint-enable @typescript-eslint/no-unused-vars */
      tempValue = {
        ...tempValue,
        [requestDataInfo.extra.key]: tempUpload,
      };
      onReadyToSubmit && onReadyToSubmit(true);
      context.assign({
        value: tempValue,
      });
      setUpload(uploadRes.upload);
    } catch (error) {
      console.error("Error putting file: ", error);
    }
  };

  const validateUpload = () => {
    const loadedFiles = (upload.files || []).filter((file) => !file.loading);

    setValidity((prevValidity) => ({
      ...prevValidity,
      minFiles: loadedFiles.length >= minFiles,
    }));
  };

  const validateFile = (file) => {
    const isFileAccepted = Array.isArray(acceptedFiles)
      ? acceptedFiles.includes(file.type)
      : true;
    const minFileSizeStatus = file.size >= minFileSize;
    const maxFileSizeStatus = file.size <= maxFileSize;

    setValidity((prevValidity) => ({
      ...prevValidity,
      acceptedFiles: isFileAccepted,
      minFileSize: minFileSizeStatus,
      maxFileSize: maxFileSizeStatus,
    }));

    return isFileAccepted && minFileSizeStatus && maxFileSizeStatus;
  };

  const removeFile = async (index) => {
    const queryDetails = {
      query: `
        mutation($upload_id: ID!, $file_id: ID!) { 
          updateUploadRemoveFile(upload_id: $upload_id, file_id: $file_id) { 
            id 
          } 
        }`,
      variables: {
        upload_id: upload.id,
        file_id: upload.files[index].id,
      },
    };
    try {
      await hooks.gql(queryDetails, context);
      const tempUpload = { ...upload };
      tempUpload.files.splice(index, 1);
      if (tempUpload.files.length === 0) {
        onReadyToSubmit && onReadyToSubmit(false);
        setValidity((prevValidity) => ({
          ...prevValidity,
          minFiles: false,
        }));
      }
      setUpload(tempUpload);
      validateUpload();
    } catch (error) {
      console.error("Error removing file: ", error);
    }
  };

  const createOrGetUpload = async (files) => {
    try {
      setUpload((prevUpload) => ({
        ...prevUpload,
        files: files.map((file) => ({
          id: file.name,
          original_filename: file.name,
          loading: "fetching", // Using "fetching" to differentiate between retrieving an upload and uploading a file.
        })),
      }));

      const response =
        upload && upload.id
          ? { data: { upload: upload } }
          : await createUpload();
      if (response.errors) {
        if (
          response.errors[0].extensions?.code === "BAD_USER_INPUT" &&
          response.errors[0].extensions.error === "UniqueViolation"
        ) {
          const queryDetails = {
            query: `query($queryUploads:KibanaQL="") { uploads(search: { query:$queryUploads }) { hits { id } } }`,
            variables: {
              foreign_type: requestDataInfo.foreign_type,
              ...(requestDataInfo.foreign_id
                ? { foreign_id: requestDataInfo.foreign_id }
                : {}),
            },
          };
          const result = await hooks.gql(queryDetails, context);
          const uploadDetails = await getUpload(
            result.data.uploads?.hits[0].id
          );
          setUpload(uploadDetails.upload);
        }
      } else {
        setUpload(response.data.upload);
        const pendingUploads = files.map((file) =>
          putFile(response.data.upload, file)
        );
        // NOTE: minFiles shouldn't block uploads, but should block interaction until
        //       complete, so validate incase would fail while pending uploads, and redo
        //       on upload completion.
        validateUpload();
        await Promise.all(pendingUploads);
        validateUpload();
      }
    } catch (error) {
      console.error("Error creating or getting upload: ", error);
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    dragCounter.current++;
    if (dragCounter.current === 1) {
      setDragging(true);
    }
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDragging(false);
    }
  };

  const onFileDrop = (e) => {
    e.preventDefault();
    if (!e.dataTransfer.files) return;
    setDragging(false);
    processFiles(e.dataTransfer.files);
  };

  const MinFilesMessage = ({ count }) => {
    if (count === 0) return "No documents are required";
    if (count === 1) return "At least 1 document is required";
    return `At least ${count} documents are required`;
  };

  return (
    <div
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onFileDrop}
    >
      <List component="nav" className="upload">
        <ListItem className={dragging ? "dragover" : "list-item"}>
          <ListItemText>
            <Typography sx={{ fontWeight: "bold" }}>{label}</Typography>
            {description && (
              <Typography dangerouslySetInnerHTML={{ __html: description }} />
            )}
            <Typography className="subtext">
              {" "}
              {accepted
                ? accepted
                : `(We will accept: minimum of ${minFiles} ${getFileTypes(
                    acceptedFiles
                  )})`}
            </Typography>
          </ListItemText>
          <div style={{ display: "flex" }}>
            <IconButton
              className="button-icon"
              onClick={handleSubmit}
              aria-label={label || "File Upload"}
            >
              <input
                type="file"
                ref={fileInputRef}
                disabled={readOnly}
                style={{ display: "none" }}
                multiple
                onChange={handleFileChange}
              />
              <UploadIcon />
            </IconButton>
            <IconButton
              className="button-icon"
              disabled={disableCamera}
              aria-label="Camera Upload"
              onClick={() => setOpenCameraModal(true)}
            >
              <PhotoCamera />
            </IconButton>
            <CameraModal
              open={openCameraModal}
              onClose={() => setOpenCameraModal(false)}
              onSave={handleCameraFileChange}
            />
          </div>
        </ListItem>

        {showFormErrors === true && (
          <div>
            <div>
              {!validity.maxFiles && (
                <FormHelperText error className="error-message">
                  A maximum of {maxFiles} Documents are allowed
                </FormHelperText>
              )}
              {!validity.minFileSize && (
                <FormHelperText error className="error-message">
                  Document size is too small
                </FormHelperText>
              )}
              {!validity.maxFileSize && (
                <FormHelperText error className="error-message">
                  Document size is too large
                </FormHelperText>
              )}
              {!validity.acceptedFiles && (
                <FormHelperText error className="error-message">
                  Document type not accepted
                </FormHelperText>
              )}
              {!validity.minFiles && (
                <FormHelperText error className="error-message">
                  <MinFilesMessage count={minFiles} />
                </FormHelperText>
              )}
            </div>
          </div>
        )}

        {upload &&
          upload.files &&
          upload.files.map((file, index) => (
            <ListItem key={file.id}>
              <div>
                {file?.loading || file?.loading === "fetching" ? (
                  <CircularProgress />
                ) : file?.previews[0]?.url ? (
                  <img
                    src={file.previews[0].url}
                    alt="preview"
                    style={{ maxWidth: "24px" }}
                  />
                ) : (
                  <DescriptionIcon
                    style={{ fontSize: 30, color: "#0000008a" }}
                  />
                )}
              </div>
              <ListItemText style={{ marginLeft: "20px" }}>
                <Typography style={{ fontWeight: 400, fontSize: "1rem" }}>
                  {file.original_filename}
                </Typography>
                <Typography variant="body2" style={{ color: "#0000008a" }}>
                  {file.loading ? "Uploading " : "Uploaded "}
                  {file.url && (
                    <>
                      &middot;
                      <a
                        href={file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#ff5240" }}
                      >
                        {" "}
                        Download
                      </a>
                    </>
                  )}
                </Typography>
              </ListItemText>
              <IconButton
                color="secondary"
                onClick={() => removeFile(index)}
                disabled={
                  readOnly ||
                  file.uploaded_at < immovableAfter ||
                  Boolean(file.loading)
                }
                aria-label="Delete File"
                style={{ color: "#0000008a" }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
      </List>
    </div>
  );
};

export default UploadAndPreview;
