import parser from "./parser";
const createHistory = require("history").createBrowserHistory;
const LZString = require("lz-string");

const namedArray = (subject) => {
  return subject.order
    ? subject.order.map((k) => {
        return Object.assign({ id: k }, subject.options[k]);
      })
    : subject;
};

const deprecatedLog = (...msg) => {
  const consoleRed =
    "background: rgb(255,87,34); color: white; padding: 2px; border-radius:2px";
  console.log("%cQJSON DEPRECATED WARNING", consoleRed, ...msg);
};

const verboseLog = (...msg) => {
  const consoleGreen =
    "background: rgb(76,175,80); color: white; padding: 2px; border-radius:2px";
  console.log("%cQJSON", consoleGreen, ...msg);
};

const indentLog = (...msg) => {
  console.log("     ", ...msg);
};

const goTo = (rawAction, context, history, groupName = "") => {
  return new Promise((resolve) => {
    const action = parser.parse(rawAction, context);

    if (typeof rawAction === "number" && rawAction < 0) {
      history.go(rawAction);
      resolve();
    } else if (/^(http:\/\/|https:\/\/)/.test(String(action).toLowerCase())) {
      // absolute URL
      window.location.href = action;
      resolve();
    } else if (action.startsWith("/")) {
      // root-relative URL
      history.push(action);
      resolve();
    } else {
      // questionId
      let pathname = window.location.pathname.split("/");
      pathname.pop();
      pathname.push(action);
      const newPath = pathname.join("/");

      if (groupName === "question.before") {
        // added this so that when user navigates from the "before" hook of a question,
        // the user can't go back to it, since there's nothing to display
        history.replace(newPath);
      } else {
        history.push(newPath);
      }
      resolve();
    }
  });
};

const reload = (context) => {
  let params = context.pick("params");
  let history = createHistory({ forceRefresh: true }); // TODO: not force
  history.replace(history.location.pathname, { ...params });
};

const copy = (subject) =>
  subject ? JSON.parse(JSON.stringify(subject)) : undefined;

export const getBreadcrumbs = (path, searchParams) => {
  const segments = path.split("/");
  const permitRegistrationId = searchParams.get("permit_registration_id");

  switch (true) {
    case path.startsWith("/dashboard/invoices"):
      return "Invoices";

    case path.startsWith("/dashboard/payments"):
      return "Payments";

    case path.startsWith("/dashboard/str-hotline"):
      return "STR Hotline";

    case path.startsWith("/dashboard/rental-units/sendLetters"):
      return "Send Letters";

    case path.startsWith("/dashboard/rental-units"):
      if (segments.length === 4 || segments.length === 5) {
        return "Rental Unit Record";
      }
      return "Rental Units";

    case path.startsWith("/dashboard/registration_history"):
      if (permitRegistrationId) {
        return `Registration History for ID ${permitRegistrationId}`;
      }
      return "Registration History";

    case path.startsWith("/dashboard/registrations"):
      return "Registrations";

    case path.startsWith("/dashboard/listings"):
      return "Listings";

    case path.startsWith("/dashboard/letters"):
      return "Letters";

    case path.startsWith("/dashboard/emails"):
      return "Emails";

    case path.startsWith("/dashboard/reported-revenue"):
      return "Reported Revenue";

    case path.startsWith("/dashboard/audit-management"):
      return "Audit Management";

    case path.startsWith(
      "/dashboard/estimated-revenue-by-quarter-by-rental-unit"
    ):
      return "Revenue Estimate";

    case path.startsWith("/dashboard/audit-discovery"):
      return "Audit Discovery";

    case path.startsWith("/dashboard/jurisdictions"):
      return "Jurisdictions";

    case path.startsWith("/dashboard/features"):
      return "Beta Features";

    case path.startsWith("/dashboard/reports/complaints"):
      return "Complaints Dashboard";

    case path.startsWith("/dashboard/reports/address-identification"):
      return "Address Identification Dashboard";

    case path.startsWith("/dashboard/reports/trend-monitoring"):
      return "Trend Monitoring Dashboard";

    case path.startsWith("/dashboard/download"):
      return "Download";

    case path.startsWith("/dashboard/reports"):
      return "Dashboard";

    default:
      return "Host Compliance";
  }
};

const setData = (key, data) => {
  sessionStorage.setItem(key, LZString.compressToUTF16(JSON.stringify(data)));
};

const getData = (key) => {
  const storedValue = sessionStorage.getItem(key);
  if (storedValue) {
    return JSON.parse(LZString.decompressFromUTF16(storedValue));
  }
  return null;
};

const arrayOfObjects = (inputObject) => {
  return Object.keys(inputObject).map((key) => {
    return {
      id: key,
      ...inputObject[key],
    };
  });
};

const questionnaireVersion1 = ["permit-registration", "remit"];

export {
  namedArray,
  deprecatedLog,
  verboseLog,
  indentLog,
  goTo,
  copy,
  reload,
  setData,
  getData,
  arrayOfObjects,
  questionnaireVersion1,
};
