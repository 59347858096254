const dot = require("dot-object");
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  Stack,
  TextField,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Autocomplete,
} from "@mui/material";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  Autocomplete as GoogleAutocomplete,
} from "@react-google-maps/api";
import { deprecatedLog, getData, questionnaireVersion1 } from "./util";
import TextMaskCustom from "./input-text-mask";
import hooks from "./hooks";

const GET_COUNTRY_CODES = gql`
  {
    iso(id: "3166-1-alpha-2")
  }
`;

const GET_STATE_CODES = gql`
  query ($id: ID!) {
    iso(id: $id)
  }
`;

const COUNTRY_POSTAL_REGEX = {
  CA: /^[A-Za-z]{1,2}\d[A-Za-z -]?\d?[A-Za-z]\d{0,2}/,
  US: /^[0-9]{5}(?:-[0-9]{4})?$/,
};

function View({ question, context }) {
  const GOOGLE_MAPS_API_KEY = context.get().config.GOOGLE_MAPS_API_KEY;
  const [formErrors, setFormErrors] = useState({});
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [lookupOptions, setLookupOptions] = useState({});
  const autocompleteRef = useRef(null);
  const isQJSONVersion1 = questionnaireVersion1.includes(
    getData("questionnaireID")
  );

  // Map
  const { isLoaded: isGoogleMapLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const basicVersion1 = {
    address1: "",
    address2: "",
    city: "",
    country_code: question.input.country_code || "US",
    state: "",
    zipcode: "",
  };

  const basicVersion2 = {
    line_1: "",
    line_2: "",
    city: "",
    state: "",
    postal_code: "",
    country_code: question.input.country_code || "US",
  };

  const DEFAULT_VALUE = {
    basic: isQJSONVersion1 ? basicVersion1 : basicVersion2,
    autocomplete: isQJSONVersion1 ? { formatted: "" } : {},
    semantic: {
      house_number: "",
      street_name: "",
      building_number: "",
      unit_number: "",
      line_1: "",
      line_2: "",
      city: "",
      state: "",
      postal_code: "",
      country_code: question.input.country_code || "US",
    },
  };
  if (!question.input.style) {
    question.input.style = "basic";
  }
  const [formValues, setFormValues] = useState({
    output: Object.assign(
      DEFAULT_VALUE[question.input.style],
      context.pick("value") ||
        typeof context.pick("answers." + question.properties.answer_key) ===
          "object"
        ? context.pick("answers." + question.properties.answer_key)
        : {}
    ),
  });

  const { data: countryData, loading: countryLoading } =
    useQuery(GET_COUNTRY_CODES);
  const { data: stateData } = useQuery(GET_STATE_CODES, {
    skip: countryLoading,
    variables: {
      id: `3166-2-${formValues.output.country_code}`,
    },
  });

  const [postalCodePattern, setPostalCodePattern] = useState(
    COUNTRY_POSTAL_REGEX[question.input.country_code || "US"]
  );
  const level2StateCountries = ["GB"];
  const countriesWithStateSet = ["US"];

  useEffect(async () => {
    if (!GOOGLE_MAPS_API_KEY) {
      deprecatedLog("Context missing `config.GOOGLE_MAPS_API_KEY`");
    }

    if (
      question.input.style !== "autocomplete" &&
      !question.input.country_code
    ) {
      deprecatedLog("input.country_code missing");
    }
    let tempValues = { ...formValues };
    if (question.input.style === "autocomplete") {
      tempValues.output = isQJSONVersion1
        ? { formatted: context.pick("answers.street_address") }
        : context.pick("answers.situs_address");
      if (question.input.position) {
        const position = context.pick(question.input.position);
        if (position) {
          tempValues.output = { ...tempValues.output, position: position };
        }
      }
      setFormValues(tempValues);
      const checkGoogleLoaded = () => {
        if (typeof window.google === "object") {
          setGoogleLoaded(true);
          const customer = isQJSONVersion1
            ? context.pick("customer")
            : context.pick("data.jurisdiction");
          const lat = isQJSONVersion1
            ? customer?.info.lat
            : customer.position?.latitude;
          const lang = isQJSONVersion1
            ? customer?.info.lng
            : customer.position?.longitude;
          if (customer) {
            const bounds = new window.google.maps.LatLngBounds(
              new window.google.maps.LatLng(lat, lang)
            );
            setLookupOptions({ bounds });
          }
        } else {
          setTimeout(checkGoogleLoaded, 200);
        }
      };

      checkGoogleLoaded();

      context.assign({
        value: isQJSONVersion1
          ? tempValues.output.formatted
          : tempValues.output,
      });
      // Cleanup interval on component unmount
      return () => clearTimeout(checkGoogleLoaded);
    }

    if (
      tempValues.output.postal_code !== "" &&
      tempValues.output.zipcode !== ""
    ) {
      validate(
        isQJSONVersion1 ? "output.zipcode" : "output.postal_code",
        isQJSONVersion1
          ? tempValues.output.zipcode
          : tempValues.output.postal_code
      );
    }
  }, []);

  const fetchStateData = async (countryCode) => {
    const queryDetails = {
      query: "query($id: ID!) {iso(id: $id)}",
      variables: {
        id: `3166-2-${countryCode}`,
      },
    };
    const result = await hooks.gql(queryDetails, context);
    return result.data.iso;
  };

  const validate = (name, value) => {
    let newErrors = { ...formErrors };

    if (name === "output.postal_code" || name === "output.zipcode") {
      newErrors.postal_code =
        value !== ""
          ? postalCodePattern?.test(value)
            ? ""
            : question.properties.locale.validation_invalid
          : question.properties.locale.validation_required;
    }

    setFormErrors(newErrors);
  };

  useEffect(() => {
    const allFieldsValid = Object.values(formErrors).every(
      (error) => error === ""
    );

    context.assign({
      canSubmit: allFieldsValid,
    });
  }, [formErrors, formValues]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    validate(name, value);

    let values = { ...formValues };
    dot.str(name, value, values);
    setFormValues(values);
    context.assign({
      value: values.output,
    });
  };

  const handlePlaceChange = (autoComplete) => {
    const place = autoComplete.getPlace();
    const { formatted_address, geometry, place_id } = place;
    const { lat, lng } = geometry.location;

    const tempValues = isQJSONVersion1
      ? { ...formValues, output: { formatted: formatted_address } }
      : {
          ...formValues,
          output: {
            formatted: formatted_address,
            position: {
              latitude: lat(),
              longitude: lng(),
            },
            google_place_id: place_id,
          },
        };

    setFormValues(tempValues);
    if (question.input.map) {
      placeToValues(place);
    } else {
      context.assign({
        value: isQJSONVersion1
          ? tempValues.output.formatted
          : tempValues.output,
      });
    }
  };

  // center={{
  //   lat: formValues.position.latitude,
  //   lng: formValues.position.longitude,
  // }}

  const onLoad = useCallback(function callback(map) {
    console.warn(map);

    // const bounds = new window.google.maps.LatLngBounds();
    // .. add marker?
    // map.fitBounds(bounds);

    // //
    // let position;
    // if (question.input.position) {
    //   console.log(1, question.input.position)
    //   position = context.pick(question.input.position)
    // } else {
    //   const tmpC = context.get().data || context.get();
    //   console.log(2, tmpC)
    //   position = tmpC.jurisdiction ? tmpC.jurisdiction.position : null;
    //   deprecatedLog('input.country_code missing');
    // }
    // console.log(55, position);
  }, []);

  const onDragEnd = async (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat: newLat, lng: newLng };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK" && results[0]) {
        placeToValues(results[0]);
      } else {
        console.error("Geocoder failed due to:", status);
      }
    });
  };

  const placeToValues = (place) => {
    let values = { ...formValues };
    // Formatted Address?
    if (place.formatted_address) {
      values.output.formatted = place.formatted_address;
    } else {
      delete values.output.formatted;
    }

    // Position?
    if (place.geometry) {
      values.output.position = {
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      };
    } else {
      delete values.output.position;
    }

    // Google place_id?
    if (place.place_id) {
      values.output.google_place_id = place.place_id;
    } else {
      delete values.output.google_place_id;
    }

    // Address Components?
    if (place.address_components) {
      // First, country code...
      const countryCode = place.address_components.filter((o) =>
        o.types.includes("country")
      )[0].short_name;

      // Second, The bulk...
      place.address_components.forEach((o) => {
        if (o.types.includes("street_number")) {
          values.output.house_number = o.long_name;
        } else if (o.types.includes("route")) {
          values.output.street = o.long_name;
        } else if (
          o.types.includes("locality") ||
          o.types.includes("postal_town")
        ) {
          values.output.city = o.long_name;
        } else if (
          o.types.includes("administrative_area_level_1") &&
          !level2StateCountries.includes(countryCode)
        ) {
          values.output.state = countriesWithStateSet.includes(countryCode)
            ? o.short_name
            : o.long_name;
        } else if (
          o.types.includes("administrative_area_level_2") &&
          level2StateCountries.includes(countryCode)
        ) {
          values.output.state = countriesWithStateSet.includes(countryCode)
            ? o.short_name
            : o.long_name;
        } else if (o.types.includes("postal_code")) {
          values.output.postal_code = o.long_name;
        } else if (o.types.includes("country")) {
          values.output.country_code = o.short_name;
          validationByCountry(o.short_name);
        }
      });

      // Third, Post-Process
      values.output.line_1 = `${values.output.house_number} ${values.output.street}`;
    }
    setFormValues(values);
    context.assign({
      value: values.output,
    });
  };

  const validationByCountry = async (countryCode) => {
    if (countryCode) {
      // State
      const stateCodes = await fetchStateData(countryCode);
      const values = { ...formValues };
      if (stateCodes.length > 0) {
        if (Object.keys(stateCodes).indexOf(values.output.state) === -1) {
          setFormValues((prevValues) => {
            const newValues = { ...prevValues };
            delete newValues.output.state;
            return newValues;
          });
        }
      }

      // Postal Code
      let pattern;
      switch (countryCode) {
        case "CA":
          pattern = /^[A-Za-z]{1,2}\d[A-Za-z -]?\d?[A-Za-z]\d{0,2}/;
          break;
        case "US":
          pattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
          break;
        default:
          pattern = null;
      }
      setPostalCodePattern(pattern);
    }
  };

  return (
    <div>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <Stack direction="row" spacing={2}>
        {question.input.map && isGoogleMapLoaded && (
          <div>
            <GoogleMap
              mapContainerStyle={{
                width: "650px",
                height: "350px",
              }}
              center={{
                lat: formValues.output.position.latitude,
                lng: formValues.output.position.longitude,
              }}
              zoom={16}
              onLoad={onLoad}
            >
              <Marker
                position={{
                  lat: formValues.output.position.latitude,
                  lng: formValues.output.position.longitude,
                }}
                onClick={() => {
                  console.log(123);
                }}
                draggable={true}
                onDragEnd={onDragEnd}
              />
            </GoogleMap>
          </div>
        )}

        {googleLoaded && question.input.style === "autocomplete" && (
          <FormControl error fullWidth>
            <GoogleAutocomplete
              onLoad={(autocomplete) =>
                (autocompleteRef.current = autocomplete)
              }
              onPlaceChanged={() => handlePlaceChange(autocompleteRef.current)}
              types={["address"]}
              options={lookupOptions}
            >
              <TextField
                label={question.properties.locale.street_address}
                id="formatted"
                name="output.formatted"
                variant="filled"
                value={formValues.output.formatted}
                onChange={handleInputChange}
                required={question.input.required}
                readOnly={question.input.readonly}
                inputProps={{
                  inputref: "ref-formatted",
                }}
                autoComplete="off"
                autofill="off"
                aria-describedby="formatted-helper-text"
                fullWidth
              />
            </GoogleAutocomplete>
            <FormHelperText
              id="formatted-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.formatted}
            </FormHelperText>
          </FormControl>
        )}
      </Stack>

      {question.input.style === "semantic" && (
        <Stack direction={{ xs: "column", sm: "row" }}>
          <FormControl error fullWidth>
            <TextField
              id="house_number"
              name="output.house_number"
              label={question.properties.locale.house_number}
              variant="filled"
              value={formValues.output.house_number}
              onChange={handleInputChange}
              required={question.input.required}
              readOnly={question.input.readonly}
              inputProps={{
                inputref: "ref-house_number",
              }}
              autoComplete="off"
              autofill="off"
              aria-describedby="house_number-helper-text"
            />
            <FormHelperText
              id="house_number-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.house_number}
            </FormHelperText>
          </FormControl>

          <FormControl error fullWidth>
            <TextField
              id="street_name"
              name="output.street_name"
              label={question.properties.locale.street_name}
              placeholder={question.properties.locale.placeholder}
              variant="filled"
              value={formValues.output.street_name}
              onChange={handleInputChange}
              required={question.input.required}
              readOnly={question.input.readonly}
              inputProps={{
                inputref: "ref-street_name",
              }}
              autoComplete="off"
              autofill="off"
              aria-describedby="street_name-helper-text"
            />
            <FormHelperText
              id="street_name-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.street_name}
            </FormHelperText>
          </FormControl>

          <FormControl error fullWidth>
            <InputLabel htmlFor="building_number">
              {question.properties.locale.building_number}
            </InputLabel>
            <Input
              id="building_number"
              name="output.building_number"
              variant="filled"
              value={formValues.output.building_number}
              inputComponent={TextMaskCustom}
              onChange={handleInputChange}
              required={question.input.required}
              readOnly={question.input.readonly}
              inputProps={{
                inputref: "ref-building_number",
                mask: `${question.properties.locale.building_number} **********`,
              }}
              autoComplete="off"
              autofill="off"
              aria-describedby="building_number-helper-text"
            />
            <FormHelperText
              id="building_number-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.building_number}
            </FormHelperText>
          </FormControl>

          <FormControl error fullWidth>
            <InputLabel htmlFor="unit_number">
              {question.properties.locale.unit_number}
            </InputLabel>
            <Input
              id="unit_number"
              name="output.unit_number"
              variant="filled"
              value={formValues.output.unit_number}
              inputComponent={TextMaskCustom}
              onChange={handleInputChange}
              required={question.input.required}
              readOnly={question.input.readonly}
              inputProps={{
                inputref: "ref-unit_number",
                mask: `${question.properties.locale.unit_number} **********`,
              }}
              autoComplete="off"
              autofill="off"
              aria-describedby="unit_number-helper-text"
            />
            <FormHelperText
              id="unit_number-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.unit_number}
            </FormHelperText>
          </FormControl>
        </Stack>
      )}

      {question.input.style === "basic" && (
        <Stack direction={{ xs: "column", sm: "row" }}>
          <FormControl error fullWidth>
            <TextField
              id="line_1"
              name={isQJSONVersion1 ? "output.address1" : "output.line_1"}
              label={
                question.properties.locale.line_1 ||
                question.properties.locale.address1
              }
              variant="filled"
              value={
                isQJSONVersion1
                  ? formValues.output.address1
                  : formValues.output.line_1
              }
              onChange={handleInputChange}
              required={question.input.required}
              readOnly={question.input.readonly}
              inputProps={{
                inputref: "ref-line_1",
              }}
              autoComplete="off"
              autofill="off"
              aria-describedby="line_1-helper-text"
            />
            <FormHelperText
              id="line_1-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.line_1}
            </FormHelperText>
          </FormControl>

          <FormControl error fullWidth>
            <TextField
              id="line_2"
              name={isQJSONVersion1 ? "output.address2" : "output.line_2"}
              label={
                question.properties.locale.line_2 ||
                question.properties.locale.address2
              }
              variant="filled"
              value={
                isQJSONVersion1
                  ? formValues.output.address2
                  : formValues.output.line_2
              }
              onChange={handleInputChange}
              readOnly={question.input.readonly}
              inputProps={{
                inputref: "ref-line_2",
              }}
              autoComplete="off"
              autofill="off"
              aria-describedby="line_2-helper-text"
            />
            <FormHelperText
              id="line_2-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.line_2}
            </FormHelperText>
          </FormControl>
        </Stack>
      )}

      {question.input.style !== "autocomplete" && (
        <Stack direction={{ xs: "column", sm: "row" }}>
          <FormControl error fullWidth>
            <TextField
              id="city"
              name="output.city"
              label={question.properties.locale.city}
              variant="filled"
              value={formValues.output.city}
              onChange={handleInputChange}
              required={question.input.required}
              readOnly={question.input.readonly}
              autoComplete="off"
              autofill="off"
              aria-describedby="city-helper-text"
            />
            <FormHelperText
              id="city-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.city}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth>
            {!stateData ||
              (Object.keys(stateData.iso).length === 0 && (
                <TextField
                  id="state"
                  name="output.state"
                  variant="filled"
                  label={question.properties.locale.state}
                  value={formValues.output.state}
                  onChange={handleInputChange}
                  required={question.input.required}
                  readOnly={question.input.readonly}
                  inputProps={{
                    inputref: "ref-state",
                  }}
                  autoComplete="off"
                  autofill="off"
                  aria-describedby="state-helper-text"
                />
              ))}

            {stateData && Object.keys(stateData.iso).length > 0 && (
              <Autocomplete
                id="state"
                options={
                  stateData && stateData.iso ? Object.keys(stateData.iso) : []
                }
                getOptionLabel={(option) => stateData.iso[option] || ""}
                value={formValues.output.state || null}
                onChange={(event, newValue) => {
                  handleInputChange({
                    target: {
                      name: "output.state",
                      value: newValue,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={question.properties.locale.state}
                    required={question.input.required}
                    InputProps={{
                      readOnly: question.input.readonly,
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            )}
            <FormHelperText
              id="state-helper-text"
              style={{ fontSize: "0.9rem" }}
            >
              {formErrors.state}
            </FormHelperText>
          </FormControl>

          <FormControl error fullWidth>
            <TextField
              id="postal_code"
              name={isQJSONVersion1 ? "output.zipcode" : "output.postal_code"}
              label={
                question.properties.locale.postal_code ||
                question.properties.locale.zipcode
              }
              variant="filled"
              value={
                isQJSONVersion1
                  ? formValues.output.zipcode
                  : formValues.output.postal_code
              }
              onChange={handleInputChange}
              required={question.input.required}
              readOnly={question.input.readonly}
              inputProps={{
                inputref: "ref-postal_code",
              }}
              autoComplete="off"
              autofill="off"
              aria-describedby="postal_code-helper-text"
              error={!!formErrors.postal_code}
              helperText={formErrors.postal_code}
            />
          </FormControl>

          <FormControl fullWidth>
            {countryData && (
              <Autocomplete
                id="country_code"
                options={
                  countryData && countryData.iso
                    ? Object.keys(countryData.iso)
                    : []
                }
                getOptionLabel={(option) => countryData.iso[option] || ""}
                value={formValues.output.country_code}
                onChange={(event, newValue) => {
                  handleInputChange({
                    target: {
                      name: "output.country_code",
                      value: newValue,
                    },
                  });
                }}
                openOnFocus
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={question.properties.locale.country_code}
                    required={question.input.required}
                    InputProps={{
                      readOnly: question.input.readonly,
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            )}
          </FormControl>
        </Stack>
      )}
    </div>
  );
}

export default View;
