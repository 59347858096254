import React, { useEffect, useState, useCallback } from "react";
import { FormControl, FormHelperText, Input, InputLabel } from "@mui/material";
import dot from "dot-object";
import TextMaskCustom from "./input-text-mask";
import hooks from "./hooks";
import debounce from "lodash.debounce";
import { getData, questionnaireVersion1 } from "./util";

function View({ question, context }) {
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    output:
      context.pick("value") ||
      context.pick("answers." + question.properties.answer_key),
  });

  useEffect(() => {
    if (question.input.required) {
      context.assign({
        canSubmit: false,
      });
    }
  }, []);

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value.trim();
    let values = { ...formValues };
    if (question.input.mask) {
      const maskedValue = maskValue(value, question.input.mask);
      dot.str(name, maskedValue, values);
    } else {
      dot.str(name, value, values);
    }

    context.assign({
      value: values.output,
    });
    setFormValues(values);
    setFormErrors({});
    debouncedValidate(value);
  };

  const maskValue = (value, mask) => {
    let maskedValue = "";
    let valueIndex = 0;

    for (let i = 0; i < mask.length; i++) {
      if (mask[i] === "9") {
        if (value[valueIndex]) {
          maskedValue += value[valueIndex];
          valueIndex++;
        } else {
          break;
        }
      } else {
        maskedValue += mask[i];
      }
    }

    return maskedValue;
  };

  const debouncedValidate = useCallback(
    debounce((value) => {
      if (value !== "") validate();
    }, 300),
    []
  );

  const validate = async () => {
    setFormErrors({ message: "Validating..." });
    let res = {};
    if (questionnaireVersion1.includes(getData("questionnaireID"))) {
      const validator =
        question.hooks?.after_validate?.[0] || question.input.validator;
      res = await hooks.api(validator, context);
    } else {
      res = await hooks.gql(question.input.validator, context);
    }

    if (res.errors || res.message) {
      context.assign({ canSubmit: false });
      setFormErrors(res.errors ? res.errors[0] : res);
    } else {
      context.assign({ canSubmit: true });
      setFormErrors({});
    }
  };

  return (
    <FormControl error fullWidth>
      <InputLabel htmlFor="text">{question.properties.locale.name}</InputLabel>
      <Input
        id="text"
        name="output"
        placeholder={question.properties.locale.placeholder}
        variant="filled"
        value={formValues.output}
        inputComponent={TextMaskCustom}
        onChange={handleInputChange}
        required={question.input.required}
        autoFocus
        inputProps={{
          maxLength: question.input.maxlength ? question.input.maxlength : 80,
          inputref: "ref-text",
          mask: question.input.mask || "***************",
        }}
        autoComplete="off"
        aria-describedby="text-helper-text"
      />
      <FormHelperText id="text-helper-text" style={{ fontSize: "0.9rem" }}>
        {formErrors.message}
      </FormHelperText>
    </FormControl>
  );
}

export default View;
