import React, { useEffect, useState } from "react";
import UploadAndPreview from "./uploadAndPreview";
import parser from "./parser";

function View({ question, context, showFormErrors }) {
  const locale = question.properties.locale;
  const input = question.input;

  // To handle version 1 QJSON
  let requestDataTemp = {};
  if (!input.data) {
    let jid = null;
    if (context.pick("jurisdiction.id")) {
      jid = context.jurisdiction.id;
    } else if (context.pick("customer.info")) {
      jid = context.pick("customer.info.geoid");
    }
    requestDataTemp = {
      jurisdiction_id: jid,
      foreign_type: "PERMIT",
    };
  } else {
    requestDataTemp = parser.parse(input.data, context);
  }
  const documents = Object.entries(question.input.documents)?.map(
    ([key, item]) => ({
      ...parser.parse(item, context),
      requestData: {
        ...requestDataTemp,
        extra: {
          key: key,
          label: locale[item.locale_key],
        },
      },
    })
  );

  const [canSubmit, setCanSubmit] = useState(false);
  const [uploadStates, setUploadStates] = React.useState({});

  const handleUploadReady = (id, isReady) => {
    setUploadStates((prev) => {
      const newStates = { ...prev, [id]: isReady };

      const allReady = Object.values(newStates).every((ready) => ready);
      setCanSubmit(allReady);

      return newStates;
    });
  };

  useEffect(() => {
    context.assign({ canSubmit });
  }, [canSubmit]);

  return (
    <div className={`inputs inputs-uploads ${locale.dir}`}>
      {documents &&
        Object.keys(documents).length > 0 &&
        Object.values(documents).map((item) =>
          item.active === false ? null : (
            <div key={item.id || item.requestData.extra.key} className="item">
              <UploadAndPreview
                id={`ref-uploads-${item.id}`}
                requestData={item.requestData}
                context={context}
                showFormErrors={
                  showFormErrors &&
                  !uploadStates[item.id || item.requestData.extra.key]
                }
                options={{
                  label: locale[item.locale_key],
                  description: locale[item.locale_key_more],
                  accepted: locale[item.locale_key_accepted],
                  readOnly: input.readonly,
                  minFileSize: item.min_file_size ? item.min_file_size : 100,
                  maxFileSize: item.max_file_size
                    ? item.max_file_size
                    : 25000000,
                  minFiles: item.min_files ? item.min_files : 0,
                  maxFiles: item.max_files ? item.max_files : 10,
                  acceptedFiles: parser.parse(item.extensions, context),
                  disableCamera: item.disable_camera,
                  immovableAge: input.immovable_age,
                }}
                onReadyToSubmit={(isReady) =>
                  handleUploadReady(
                    item.id || item.requestData.extra.key,
                    isReady
                  )
                }
              />
            </div>
          )
        )}
    </div>
  );
}

export default View;
