import React, { useRef, useState, useCallback } from "react";
import { Buffer } from "buffer";
import Webcam from "react-webcam";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";

const CameraModal = ({ open, onClose, onSave }) => {
  const webcamRef = useRef(null);
  const [cameraReady, setCameraReady] = useState(false);

  const dataURItoBlob = (dataURI) => {
    const byteString = Buffer.from(dataURI.split(",")[1], "base64");
    return new File([byteString], "image.png", { type: "image/png" });
  };

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        onSave(dataURItoBlob(imageSrc));
        onClose();
      }
    }
  }, [onSave, onClose]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{ height: "auto" }}
    >
      <DialogTitle sx={{ backgroundColor: "rgb(229,22,61)", color: "white" }}>
        Take a Photo
      </DialogTitle>

      <DialogContent>
        <Webcam
          ref={webcamRef}
          screenshotFormat="image/png"
          videoConstraints={{
            width: 1000,
            height: 700,
          }}
          onUserMedia={() => setCameraReady(true)}
          onUserMediaError={() => console.warn("Camera access denied")}
          style={{ width: "100%" }}
        />
      </DialogContent>

      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={onClose}>Close</Button>
        <Button
          onClick={capture}
          disabled={!cameraReady}
          variant="contained"
          sx={{
            background:
              "linear-gradient(to right, rgb(255, 82, 64), rgb(255, 82, 64))",
            borderRadius: "25px",
            color: "rgb(255, 255, 255)",
            fontWeight: "bold",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          Take Photo
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CameraModal;
