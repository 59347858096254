import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, FormHelperText } from "@mui/material";

import parser from "./parser";
import hooks from "./hooks";
import {
  goTo,
  deprecatedLog,
  verboseLog,
  reload,
  questionnaireVersion1,
  getData,
} from "./util";

import Address from "./question-address";
import CheckList from "./question-check-list";
import Date from "./question-date";
import Email from "./question-email";
import Filings from "./question-filings";
import ID from "./question-id";
import IdentityExtraction from "./question-identity-extraction";
import Invoice from "./question-invoice";
import Listings from "./question-listings";
import Message from "./question-message";
import Number from "./question-number";
import RadioList from "./question-radio-list";
import Radio from "./question-radio";
import Signature from "./question-signature";
import SigninToken from "./question-signin-token";
import Submission from "./question-submission";
import SummaryMedia from "./question-summary-media";
import Summary from "./question-summary";
import Text from "./question-text";
import Textarea from "./question-textarea";
import UploadSpreadsheet from "./question-upload-spreadsheet";
import Uploads from "./question-uploads";
import PaymentMethod from "./question-payment-method";
import Currency from "./question-currency";
import { useHistory, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";

function View({ question, context, goBack }) {
  const [loaded, setLoaded] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryStringValue = searchParams.get("value");
  const currentUrl = window.location.href;
  const isFromDashboard = currentUrl.includes("/dashboard/");
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  // Loop - To verify multiple documents
  if (question.properties && question.properties.loop) {
    let change = {};
    let index = context.pick("params.index") || 0;
    let arr = context.pick(question.properties.loop);
    let loop = {};

    if (arr) {
      loop.index = index;
      loop.length = arr.length;
      loop.item = arr[loop.index];
    }
    change["loop"] = loop;
    change["params.index"] = index;
    context.assign(change);
  }

  useEffect(() => {
    let isMounted = true;

    const loadStripeKey = async () => {
      const stripeKey = questionnaireVersion1.includes(
        getData("questionnaireID")
      )
        ? context.pick("customer.paymentDetails.stripeKey")
        : context.pick("data.jurisdiction.stripe_key");

      if (stripeKey) {
        const stripeInstance = await loadStripe(stripeKey);
        if (isMounted) setStripePromise(stripeInstance);
      }
    };

    loadStripeKey();

    return () => {
      isMounted = false;
    };
  }, [showFormErrors, location]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      let value;

      if (question.properties?.context) {
        value = context.pick(question.properties.context);

        if (!value && question.properties.answer_default) {
          deprecatedLog(
            `question used 'question.properties.answer_default', use hooks`
          );
          value = parser.parse(question.properties.answer_default, context);
        }

        context.assign({
          value: value ? value : queryStringValue,
        });
      }

      if (isMounted) setLoaded(false);
      const before =
        question.hooks && question.hooks.before ? question.hooks.before : [];
      await hooks.chain("question.before", before, context, history);

      const afterLoad =
        question.hooks && question.hooks.after_load
          ? question.hooks.after_load
          : [];
      await hooks.chain("question.after_load", afterLoad, context, history);
      if (isMounted) setLoaded(true);
    };
    fetchData();

    return () => {
      isMounted = false;
    };
  }, [location]);

  context.assign({
    locale: question.properties?.locale,
  });

  let submitCount = 0;

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) e.preventDefault();

    if (submitCount > 0) return;
    submitCount++;

    const { canSubmit } = context.get();
    if (canSubmit === false) {
      submitCount--;
      setShowFormErrors(true);
      return;
    }

    if (question.properties?.answer_key) {
      question.properties.context = `answers.${parser.interpolate(
        question.properties.answer_key,
        context.get()
      )}`;
      deprecatedLog(
        `question used 'question.properties.answer_key', use 'question.properties.context'`
      );
    }

    const beforeSave =
      question.hooks && question.hooks.before_save
        ? question.hooks.before_save
        : [];
    const afterSave =
      question.hooks && question.hooks.after_save
        ? question.hooks.after_save
        : [];
    const after =
      question.hooks && question.hooks.after ? question.hooks.after : [];

    if (
      context.pick("params.questionId") === "invoice" &&
      question.input.mutable
    ) {
      var lineItems = context
        .pick("value.lineItems") // eslint-disable-line no-use-before-define
        .filter(function (o) {
          return (o.mutable && o.mutable.active && o.active) || o.active;
        })
        .map(function (o) {
          return {
            type: o.type,
            amount: parseInt(o.amount),
            description: o.description,
          };
        });

      const change = {};
      context.del("answers.extra.lineItems");
      change["answers.extra.lineItems"] = lineItems;
      change["value.lineItems"] = lineItems;
      context.assign(change);

      if (context.pick("value.id")) {
        const queryDetails = {
          query:
            "mutation($id:ID! $lineItems:[EditInvoiceLineItem]!) { updateInvoice(id:$id lineItems:$lineItems) { subtotal } }",
          variables: {
            id: "{{ value.id }}",
            lineItems: "{{ value.lineItems }}",
          },
        };
        let result = await hooks.gql(queryDetails, context);
        if (result.errors) {
          return;
        }
      }
      // end TECHDEBT
    }
    const initialPath = window.location.pathname;

    hooks
      .chain("question.before_save", beforeSave, context, history)
      .then(async () => {
        if (window.location.pathname !== initialPath) {
          return;
        }

        let change = {};

        if (!context.pick("answers.checksum") && context.pick("checksum")) {
          change["answers.checksum"] = context.pick("checksum");
        }
        if (question.properties.context && context.pick("value")) {
          change[question.properties.context] = context.pick("value");
        }
        context.assign(change);
        verboseLog("Set Context", change);
        if (
          question.input.save &&
          (question.input.save.active
            ? parser.logic(question.input.save.active, context.get())
            : true)
        ) {
          let result = {};
          if (questionnaireVersion1.includes(getData("questionnaireID"))) {
            result = await hooks.api(question.input.save, context);
          } else {
            result = await hooks.gql(question.input.save, context);
          }

          if (result.errors || result.message) {
            const regex = /<[^>]*>/;
            const message =
              result.errors && regex.test(result.errors[0].message)
                ? "Failed to submit the request. Please try again."
                : result.errors
                ? result.errors[0].message
                : result.message;
            throw new Error(message);
          }
        }

        if (question.input.type === "submission") {
          const doSave = async () => {
            // Function to handle errors
            const buildErrorHandler = (prefix) => (res) => {
              let msg = `${prefix} `;
              let errors = res?.data?.errors || [];

              if (errors.length === 1) {
                msg = errors[0].message;
              } else {
                msg = `${errors.length} errors.`;
                errors.forEach((error, i) => {
                  msg += ` Error ${i + 1}) ${error.message}`;
                });
              }
              console.warn(msg);
            };

            try {
              if (questionnaireVersion1.includes(getData("questionnaireID"))) {
                if (
                  parser.logic(question.input.payment.active, context.get())
                ) {
                  question.input.payment.data = {
                    "value.payment": "payment",
                    ...question.input.payment.data,
                  };
                  const payment = await hooks.api(
                    question.input.payment,
                    context
                  );
                  if (payment.errors || payment.message) {
                    const regex = /<[^>]*>/;
                    const message =
                      payment.errors && regex.test(payment.errors[0].message)
                        ? "Failed to submit the request. Please try again."
                        : payment.errors
                        ? payment.errors[0].message
                        : payment.message;
                    throw new Error(message);
                  }
                }
              } else {
                let payment = {};
                if (
                  question.input.payment.active ||
                  parser.logic(question.input.payment.active, context.get())
                ) {
                  payment = context.pick("value.payment");
                }

                if (
                  question.input.payment.active ||
                  parser.logic(question.input.payment.active, context.get())
                ) {
                  const paymentVariables = parser.parse(
                    question.input.payment.variables,
                    context
                  );

                  // Remove invalid or empty payment variables
                  Object.keys(paymentVariables).forEach((key) => {
                    if (
                      !paymentVariables[key] ||
                      paymentVariables[key] === "undefined" ||
                      paymentVariables[key] === "null" ||
                      paymentVariables[key] === "" ||
                      /\{\{.*\}\}/.test(paymentVariables[key]) // remove placeholders
                    ) {
                      delete paymentVariables[key];
                    }
                  });

                  let query = "mutation($item:NewPayment!";
                  let variables = { item: { ...payment, ...paymentVariables } };

                  if (!variables.item.invoice_id) {
                    variables.tmp = context.answers;
                    query += " $tmp:JSON";
                  }

                  query += ") { createPayment(item:$item";

                  if (!variables.item.invoice_id) {
                    query += " tmp:$tmp";
                  }

                  query += ") { id }}";

                  const queryDetails = { query: query, variables: variables };
                  let result = await hooks.gql(queryDetails, context);

                  if (result.error) {
                    return;
                  }
                }
              }
            } catch (error) {
              buildErrorHandler("Submission failed")(error);
            }
          };

          await doSave();
        }
      })
      .then(() => {
        if (window.location.pathname !== initialPath) {
          return;
        }
        return hooks.chain("question.after_save", afterSave, context, history);
      })
      .then(() => {
        if (window.location.pathname !== initialPath) {
          return;
        }
        return hooks.chain("question.after", after, context, history);
      })
      .then(() => {
        if (window.location.pathname !== initialPath) {
          return;
        }
        if (
          question.properties.loop &&
          context.pick("loop.index") + 1 !== context.pick("loop.length")
        ) {
          let params = context.pick("params");
          let loop = context.pick("loop");
          context.assign({
            params: {
              ...params,
              index: params.index + 1,
            },
            loop: {
              ...loop,
              index: params.index + 1,
            },
          });
          reload(context);
        } else {
          submitCount--;
          goTo(question.properties?.action, context, history);
        }
      })
      .catch((error) => {
        submitCount--;
        setShowFormErrors(true);
        setErrorMessage(error.message);
        console.error(error.message);
      });
  };

  const onGoBack = () => {
    context.assign({
      canSubmit: true,
    });
    setShowFormErrors(false);
    setErrorMessage("");
    goBack();
  };

  document.onkeydown = (e) => {
    if (
      e &&
      e.key === "Enter" &&
      e.target.name !== "back" &&
      e.target.name !== "submit"
    ) {
      e.preventDefault();
    }
  };

  return !loaded ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <form
      onSubmit={handleSubmit}
      className={isFromDashboard ? " qjson dashboardQjson" : "qjson"}
    >
      <h2 dir={question?.properties?.locale?.dir}>
        {question?.input?.required !== true &&
          [
            "address",
            "date",
            "email",
            "id",
            "number",
            "radio-list",
            "text",
            "textarea",
          ].includes(question?.input?.type) && (
            <span>{question?.properties?.locale?.validation_optional}: </span>
          )}
        {parser.parse(question?.properties?.locale?.title, context)}
      </h2>

      {question.properties?.locale.prepend && (
        <p
          dir={question.properties?.locale.dir}
          className="prepend"
          style={{ lineHeight: "1.5" }}
          dangerouslySetInnerHTML={{
            __html: parser.parse(question.properties?.locale.prepend, context),
          }}
        ></p>
      )}

      {question.input.type === "address" && (
        <Address question={question} context={context}></Address>
      )}
      {question.input.type === "check-list" && (
        <CheckList
          question={question}
          context={context}
          showFormErrors={showFormErrors}
        ></CheckList>
      )}
      {question.input.type === "date" && (
        <Date question={question} context={context}></Date>
      )}
      {question.input.type === "email" && (
        <Email question={question} context={context}></Email>
      )}
      {(question.input.type === "filings" ||
        question.input.type === "receipts") && (
        <Filings
          question={question}
          context={context}
          showFormErrors={showFormErrors}
        ></Filings>
      )}
      {question.input.type === "id" && (
        <ID question={question} context={context}></ID>
      )}
      {question.input.type === "identity-extraction" && (
        <IdentityExtraction
          question={question}
          context={context}
        ></IdentityExtraction>
      )}
      {question.input.type === "invoice" && (
        <Invoice question={question} context={context}></Invoice>
      )}
      {question.input.type === "listings" && (
        <Listings
          question={question}
          context={context}
          showFormErrors={showFormErrors}
        ></Listings>
      )}
      {question.input.type === "message" && (
        <Message question={question} context={context}></Message>
      )}
      {question.input.type === "number" && (
        <Number question={question} context={context}></Number>
      )}
      {question.input.type === "radio-list" && (
        <RadioList
          question={question}
          context={context}
          showFormErrors={showFormErrors}
        ></RadioList>
      )}
      {question.input.type === "radio" && (
        <Radio question={question} context={context}></Radio>
      )}
      {question.input.type === "signature" && (
        <Signature question={question} context={context}></Signature>
      )}
      {question.input.type === "signin-token" && (
        <SigninToken question={question} context={context}></SigninToken>
      )}
      {question.input.type === "submission" &&
        (questionnaireVersion1.includes(getData("questionnaireID"))
          ? context.pick("payment")
          : context.pick("answers.invoice")) &&
        (stripePromise ? (
          <Elements stripe={stripePromise}>
            <Submission question={question} context={context} />
          </Elements>
        ) : null)}
      {question.input.type === "summary-media" && (
        <SummaryMedia
          question={question}
          context={context}
          showFormErrors={showFormErrors}
        ></SummaryMedia>
      )}
      {question.input.type === "summary" && (
        <Summary question={question} context={context}></Summary>
      )}
      {question.input.type === "text" && (
        <Text question={question} context={context}></Text>
      )}
      {question.input.type === "textarea" && (
        <Textarea question={question} context={context}></Textarea>
      )}
      {question.input.type === "upload-spreadsheet" && (
        <UploadSpreadsheet
          question={question}
          context={context}
        ></UploadSpreadsheet>
      )}
      {question.input.type === "uploads" && (
        <Uploads
          question={question}
          context={context}
          showFormErrors={showFormErrors}
        ></Uploads>
      )}

      {question.input.type === "payment-method" && (
        <PaymentMethod question={question} context={context}></PaymentMethod>
      )}

      {question.input.type === "currency" && (
        <Currency question={question} context={context}></Currency>
      )}

      {question.properties?.locale.append && (
        <p
          dir={question.properties?.locale.dir}
          className="append"
          dangerouslySetInnerHTML={{
            __html: parser.parse(question.properties?.locale.append, context),
          }}
        ></p>
      )}
      {showFormErrors && errorMessage && (
        <FormHelperText error className="error-message">
          {errorMessage}
        </FormHelperText>
      )}
      <div>
        {question.properties?.action_button &&
          question.properties?.action_button.active !== false && (
            <nav
              dir={question.properties?.locale.dir}
              className="action-button"
              onClick={() => {
                goTo(
                  question.properties?.action_button.action,
                  context,
                  history
                );
              }}
            >
              <Button variant="outlined" className="action-button-nav">
                <HelpRoundedIcon
                  style={{
                    marginRight: 5,
                  }}
                />
                {
                  question.properties?.locale[
                    question.properties?.action_button.locale_key
                  ]
                }
              </Button>
            </nav>
          )}

        <nav
          dir={question.properties?.locale.dir}
          className="back-forward"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {question.properties?.back !== false && (
            <Button
              variant="outlined"
              className="back"
              onClick={onGoBack}
              name="back"
              style={{ marginRight: "auto" }}
            >
              Back
            </Button>
          )}

          {question.properties?.action && (
            <Button
              variant="outlined"
              className="forward"
              type="submit"
              name="submit"
            >
              <span className="button">
                {parser.parse(
                  question.properties?.locale.nav_forward || "Next",
                  context
                )}
              </span>
              <span className="enter">
                press <strong>Enter</strong>
              </span>
            </Button>
          )}
        </nav>
      </div>
    </form>
  );
}

export default View;
