import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import parser from "./parser";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { namedArray } from "./util";

const methodsAllHaveSamePrice = (payment_methods) => {
  return !payment_methods?.filter(function (o) {
    return o.amount_due !== payment_methods[0].amount_due;
  }).length;
};

function View({ question, context }) {
  const [interval, setInterval] = useState([]);
  const [expanded, setExpanded] = useState({});
  const input = parser.parse(question.input, context); // Input data
  const locale = question.properties.locale; // Locale data
  const paymentMethods = context.pick("payment_methods");
  const sameAmountDue = methodsAllHaveSamePrice(paymentMethods);
  const payments = sameAmountDue ? paymentMethods?.slice(0, 1) : paymentMethods;
  const frequency = context
    .pick("customer.taxablePeriod.revenueReportFrequency")
    ?.slice(0, -2);
  const options = Array.isArray(input.itemization.options)
    ? input.itemization.options
    : namedArray(input.itemization);

  useEffect(() => {
    const updatedIntervals = context.pick("interval")?.map((item) => {
      let total = 0;
      question.input.itemization.options = options;

      options.forEach((foo) => {
        item[foo.key] = parseFloat(item[foo.key]);
        if (isNaN(item[foo.key])) {
          item[foo.key] = 0;
        }
        total += item[foo.key];
      });
      return { ...item, total };
    });
    setInterval(updatedIntervals);
  }, []);

  const toggleExpand = (index) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const sum = (...args) => {
    const subject = args.length === 1 ? args[0] : Object.values(args);
    return (
      subject &&
      subject.filter((d) => d !== undefined).reduce((a, b) => a + Number(b), 0)
    );
  };

  const sumCol = (arr, key) => {
    return sum(arr?.map((o) => o[key]));
  };

  return (
    <div>
      <List>
        {payments?.map((payment, index) => (
          <div key={index}>
            <ListItem style={{ display: "block" }}>
              {(sameAmountDue ? paymentMethods : [payment]).map(
                (method, index) => (
                  <Typography variant="h6" key={index}>
                    {method.name === "ach" && <strong>Bank Account</strong>}
                    {method.name === "card" && (
                      <strong>Credit or Debit Card</strong>
                    )}
                    {method.name === "cash" && <strong>Cash</strong>}
                    {method.name === "check" && <strong>Check</strong>}
                  </Typography>
                )
              )}
              <Typography className="subtext">
                {sameAmountDue ? "Payment Methods" : "Payment Method"}
              </Typography>
              <ListItemSecondaryAction>
                <Typography variant="h6" component="strong">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(payment.amount_due)}
                </Typography>
                <IconButton edge="end" onClick={() => toggleExpand(index)}>
                  {expanded[index] ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            {expanded[index] &&
              payment.itemization.map((item, itemIndex) => (
                <ListItem key={itemIndex}>
                  <ListItemText primary={item.description} />
                  <ListItemSecondaryAction className="secondary-item">
                    <Typography component="p" className="secondary-item-text">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(item.amount)}
                    </Typography>
                    <div></div>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            <Divider />
          </div>
        ))}
      </List>
      {interval && interval.length > 0 && (
        <TableContainer
          component={Paper}
          className="show-gt-sm"
          style={{ marginTop: "20px" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell className="table-cell">
                  {locale.taxable_receipts}
                </TableCell>

                {options.map((item, index) => (
                  <TableCell key={index} className="table-cell">
                    {locale[item.locale_key]}
                  </TableCell>
                ))}
                <TableCell
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <strong style={{ fontSize: "16px" }}>Total</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {interval?.map((period, periodIndex) => (
                <TableRow key={periodIndex}>
                  <TableCell component="th" scope="row" className="table-cell">
                    {period.site === "airbnb" && <span>AirBnB</span>}
                    {period.site === "homeaway" && <span>VRBO/HomeAway</span>}
                    {new Date(period.period_start).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                    })}
                    {frequency === "quarter" && (
                      <span>
                        {" "}
                        to{" "}
                        {new Date(period.period_end).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "short",
                          }
                        )}
                      </span>
                    )}
                  </TableCell>
                  <TableCell className="table-cell">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      useGrouping: true,
                    }).format(period.taxable_receipts)}
                  </TableCell>
                  {options.map((item, idx) => (
                    <TableCell
                      key={idx}
                      style={{ textAlign: "center", fontSize: "16px" }}
                    >
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        useGrouping: true,
                      }).format(period[item.key])}
                    </TableCell>
                  ))}
                  <TableCell className="table-cell">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      useGrouping: true,
                    }).format(period.total)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <tfoot>
              <TableRow>
                <TableCell className="table-cell">Total</TableCell>
                <TableCell className="table-cell">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    useGrouping: true,
                  }).format(sumCol(interval, "taxable_receipts"))}
                </TableCell>
                {options.map((item, index) => (
                  <TableCell key={index} className="table-cell">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      useGrouping: true,
                    }).format(sumCol(interval, item.key))}
                  </TableCell>
                ))}
                <TableCell className="table-cell">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    useGrouping: true,
                  }).format(sumCol(interval, "total"))}
                </TableCell>
              </TableRow>
            </tfoot>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default View;
