import React, { useEffect, useState } from "react";

// Google Maps
import { Wrapper } from "@googlemaps/react-wrapper";

// Browser
import { createBrowserHistory } from "history";

// 3rd Party
import { IntercomProvider } from "react-use-intercom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { useAuth0 } from "@auth0/auth0-react";
import { gql, useQuery } from "@apollo/client";

// Common
import env from "../common/env";

// Our
import { JurisdictionContext } from "../dashboard/common/JurisdictionContext";
import Routes from "./Routes.jsx";

// Sentry
const history = createBrowserHistory();

Sentry.init({
  dsn: env.SENTRY_URL,
  environment: env.ENVIRONMENT,
  release: "frontend@" + env.CIRCLE_SHA1,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1,
  ignoreErrors: [
    /CloudFlare-AlwaysOnline/,
    /chrome-extension/,
    /safari-extension/,
    /linkifyContainer/,
  ],
  denyUrls: [
    // Local
    /local\.hostcompliance\.com/i,
    // Browser extensions
    /extensions\//i,
    /extension:\//i,
    /extensions:\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});

export const Router = () => {
  // Getting Jurisdiction details to use in side menu
  const { user, getAccessTokenSilently, getIdTokenClaims, isAuthenticated } =
    useAuth0();
  const GET_JURISDICTION = gql`
    query ($id: ID!) {
      jurisdiction(id: $id) {
        id
        name
        slug
        show_send_letter_button
        revert_to_legacy
      }
    }
  `;

  const [jurisdictionId, setJurisdictionId] = useState("");

  useEffect(() => {
    user &&
      setJurisdictionId(user["https://api.hostcompliance.com/metadata"].geoid);
  });

  useEffect(() => {
    let userGEOID = jurisdictionId;
    if (env.CA_BASE_HOST) {
      if (
        userGEOID.indexOf("CA") > -1 &&
        window.location.href.indexOf(env.CA_BASE_HOST.split("https://")[1]) ==
          -1
      ) {
        console.log("Redirecting to CA portal...");
        window.location.href = env.CA_BASE_HOST;
      } else if (
        userGEOID.indexOf("US") > -1 &&
        window.location.href.indexOf(env.CA_BASE_HOST.split("https://")[1]) > -1
      ) {
        console.log("Redirecting to US portal...");
        window.location.href = env.US_BASE_HOST;
      }
    }
  }, [jurisdictionId]);

  const { data: jurisdictionData, client: jurisdictionClient } = useQuery(
    GET_JURISDICTION,
    {
      variables: { id: jurisdictionId },
      skip:
        jurisdictionId === null ||
        jurisdictionId === undefined ||
        jurisdictionId === "",
    }
  );
  localStorage.setItem("jurisdiction", jurisdictionId);
  localStorage.setItem(
    "jurisdictionSlug",
    jurisdictionData?.jurisdiction?.slug
  );

  // TECHDEBT: NOTE: this sync's session for AngularJS app. Remove when deprecated.
  useEffect(() => {
    async function setSession() {
      let session = {
        token_type: "Bearer",
      };
      session.access_token = await getAccessTokenSilently();
      session.id_token = (await getIdTokenClaims()).__raw;
      localStorage.setItem(
        "com.hostcompliance.session",
        JSON.stringify(session)
      ); // eslint-disable-line no-undef
    }
    if (isAuthenticated) {
      setSession();
    }
  }, [getAccessTokenSilently, getIdTokenClaims]);

  // PENDO INITIALIZATION
  if (
    jurisdictionData &&
    user["https://api.hostcompliance.com/geoid"] &&
    process.env.REACT_APP_ENVIRONMENT === "production"
  ) {
    window.pendo.initialize({
      visitor: {
        id: user.name,
        user_email: user.email,
      },
      account: {
        id: jurisdictionData.jurisdiction.name,
        account_geoid: user["https://api.hostcompliance.com/geoid"],
      },
    });
  }

  return (
    <JurisdictionContext.Provider
      value={{
        jurisdictionName: jurisdictionData?.jurisdiction?.name,
        showSendLetter: jurisdictionData?.jurisdiction?.show_send_letter_button,
      }}
    >
      <Wrapper apiKey={env.GOOGLE_MAPS_API_KEY} libraries={["places"]}>
        <IntercomProvider
          appId={env.INTERCOM_APP_ID}
          autoBoot={env.ENVIRONMENT === "production"}
        >
          <Routes
            jurisdictionClient={jurisdictionClient}
            jurisdictionData={jurisdictionData}
          />
        </IntercomProvider>
      </Wrapper>
    </JurisdictionContext.Provider>
  );
};
