const dot = require("dot-object");
import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { arrayOfObjects } from "./util";
import parser from "./parser";

function View({ question, context, showFormErrors }) {
  const [formValidations, setFormValidations] = useState({});
  const [formValues, setFormValues] = useState({
    output: {},
    search: "",
  });
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (question.input.groups) {
      loadData();
    }
  }, []);

  const validate = (target, groupIndex) => {
    let temp = [...formValidations];
    const isValidationRequired =
      groups[groupIndex]?.max || groups[groupIndex]?.min;

    if (isValidationRequired && target.type === "checkbox") {
      const count = target.checked
        ? temp[groupIndex].count + 1
        : temp[groupIndex].count - 1;
      const isMinValid = groups[groupIndex].min
        ? count >= groups[groupIndex].min
        : temp[groupIndex].isMinValid;
      const isMaxValid = groups[groupIndex].max
        ? count <= groups[groupIndex].max
        : temp[groupIndex].isMaxValid;
      temp[groupIndex] = { ...temp[groupIndex], count, isMinValid, isMaxValid };
    } else if (
      groups[groupIndex]?.options?.[0]?.required &&
      target.type === "checkbox"
    ) {
      const isValid = target.checked;
      temp[groupIndex] = { ...temp[groupIndex], isValid };
    }
    setFormValidations(temp);
    return temp.every(
      (validation) =>
        (validation && validation.isValid) ||
        (validation?.isMaxValid && validation?.isMinValid)
    );
  };

  const loadData = () => {
    const dotKey = question.input["check-list"];
    let groups = [];
    // Build groups
    if (!question.input["check-list"] && question.input.groups) {
      groups = question.input.groups;
    } else {
      groups = [{ $checked: 0 }];
    }

    // Assign option
    if (!dotKey && !groups[0].options) {
      groups[0].options = context.pick("options");
    } else if (!groups[0].order && !groups[0].options) {
      const options = context.pick(dotKey);
      groups = Array.isArray(options) ? options : [options];
    }

    groups = groups.map((group, index) => {
      if (group.checked) {
        groupToggleAll(index);
      }

      if (group.order === undefined) {
        group.order = (group.options || []).map((o) => o.id);
      }

      if (group.options) {
        group.options = Array.isArray(group.options)
          ? group.options
          : arrayOfObjects(group.options);
      }

      if (Array.isArray(group.options)) {
        group.options.forEach((item) => {
          if (item.id === undefined) {
            item.id = "option_" + item[question.input.value_key];
          }

          if (item.locale_key === undefined) {
            item.locale_key = "label_" + item.id;
          }

          if (item.locale_key_more === undefined) {
            item.locale_key_more = "label_more_" + item.id;
          }

          item.locale_string = parser.interpolate(
            question.properties.locale[item.locale_key] ||
              question.properties.locale.label,
            item
          );
          item.locale_string_more = parser.interpolate(
            question.properties.locale[item.locale_key_more] ||
              question.properties.locale.label_more,
            item
          );
        });
      }
      if (!Array.isArray(group.options)) {
        group.options = [];
      }
      group.items = [...group.options];
      return group;
    });

    const answerKey = question.properties.answer_key;
    let answer = context.pick(`answers.${answerKey}`);

    if (answerKey && answer) {
      setFormValues({
        output: answer,
        search: "",
      });
    }

    let groupValidations = groups
      .map((group) => {
        if (group.min || group.max) {
          let count = 0;
          group.options.forEach((item) => {
            if (answer && answer[item.id]) {
              count++;
            }
          });
          return {
            minMessage:
              question.properties.locale.validation_minimum + " " + group.min,
            maxMessage:
              question.properties.locale.validation_maximum + " " + group.max,
            count: count,
            isMinValid: count >= group.min,
            isMaxValid: true,
          };
        } else if (group.options[0]?.required) {
          return {
            message: question.properties.locale.validation_required,
            isValid:
              answer && answer[group.options[0].id] === true ? true : false,
          };
        }
        return null;
      })
      .filter((validation) => validation !== null);
    if (groupValidations.length > 0) {
      const canSubmit = groupValidations.every(
        (validation) =>
          (validation && validation.isValid) ||
          (validation?.isMaxValid && validation?.isMinValid)
      );
      context.assign({
        canSubmit: canSubmit,
      });
    }
    setFormValidations(groupValidations);
    setGroups(groups);
  };

  const filter = (value) => {
    const tempGroups = [...groups];
    tempGroups.forEach((group) => {
      if (group.options) {
        group.items = group.options.filter((item) => {
          return JSON.stringify(item).indexOf(value) > -1;
        });
      }
    });
    setGroups(tempGroups);
  };

  const groupToggleAll = (groupIndex) => {
    const newGroups = [...groups];
    const group = newGroups[groupIndex];
    let values = { ...formValues };

    group.order.forEach((id) => {
      values.output[id] = group.$checked === group.order.length ? false : true;
    });

    group.$checked =
      group.$checked === group.order.length ? 0 : group.order.length;
    newGroups[groupIndex] = group;
    setFormValues(values);
    setGroups(newGroups);
    setFormValidations(group.$checked ? {} : formValidations);
    context.assign({
      value: values.output,
      canSubmit: group.$checked,
    });
  };

  const groupIsChecked = (groupIndex) => {
    const group = groups[groupIndex];
    return group.$checked === group.order.length;
  };

  const handleInputChange = (e, groupIndex) => {
    const { name } = e.target;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (name === "search") filter(value);

    let values = { ...formValues };
    dot.str(name, value, values);

    if (question.input.filtered === true) {
      Object.keys(values.output).forEach((k) => {
        if (values.output[k] === false) {
          delete values.output[k];
        }
      });
    }

    setFormValues(values);
    let canSubmit = validate(e.target, groupIndex);
    context.assign({
      value: values.output,
      canSubmit: canSubmit,
    });
  };

  return (
    <div
      dir={question.properties.locale.dir}
      className="inputs inputs-check-list"
    >
      {question.input.searchable && (
        <FormControl error fullWidth>
          <TextField
            id="searchable"
            name="search"
            label={question.properties.locale.searchable}
            variant="filled"
            value={formValues.search}
            onChange={handleInputChange}
            autoFocus
            inputProps={{
              inputref: "ref-searchable",
            }}
            autoComplete="off"
            aria-describedby="searchable-helper-text"
          />
        </FormControl>
      )}

      {groups.map((group, index) => {
        return (
          <FormGroup key={index}>
            <h2>{question.properties.locale[group.locale_key]}</h2>
            {group.all && (
              <FormControlLabel
                label={
                  <React.Fragment>
                    <h3>
                      {groupIsChecked(index)
                        ? question.properties.locale.uncheck_all
                        : question.properties.locale.check_all}
                    </h3>
                  </React.Fragment>
                }
                control={
                  <Checkbox
                    aria-label="Select All"
                    checked={groupIsChecked(index)}
                    onClick={() => groupToggleAll(index)}
                    sx={{
                      color: "#0000008a",
                      "&.Mui-checked": {
                        color: "#ff5240",
                      },
                    }}
                  />
                }
              />
            )}
            {group.items?.map((item) => {
              return (
                <FormControlLabel
                  key={item.id}
                  label={
                    <React.Fragment>
                      <h3
                        dangerouslySetInnerHTML={{
                          __html: item.locale_key && item.locale_string,
                        }}
                      ></h3>
                      {item.locale_key_more && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item.locale_string_more,
                          }}
                        ></p>
                      )}
                    </React.Fragment>
                  }
                  control={
                    <Checkbox
                      id={`ref-${item.id}`}
                      name={`output.${item.id}`}
                      readOnly={question.input.readonly}
                      value={formValues.output[item.id]}
                      checked={!!formValues.output[item.id]}
                      inputProps={{
                        maxLength: question.input.maxlength
                          ? question.input.maxlength
                          : 80,
                        inputref: `ref-${item.id}`,
                      }}
                      onChange={(e) => handleInputChange(e, index)}
                      sx={{
                        color: "#0000008a",
                        "&.Mui-checked": {
                          color: "#ff5240",
                        },
                      }}
                    />
                  }
                />
              );
            })}
            {showFormErrors &&
              group.min &&
              !formValidations[index]?.isMinValid && (
                <FormHelperText error className="error-message">
                  {formValidations[index].minMessage}
                </FormHelperText>
              )}
            {showFormErrors && group.max && !formValidations[index].isMaxValid && (
              <FormHelperText error className="error-message">
                {formValidations[index].maxMessage}
              </FormHelperText>
            )}
            {showFormErrors && !formValidations[index]?.isValid && (
              <FormHelperText error className="error-message">
                {formValidations[index]?.message}
              </FormHelperText>
            )}
          </FormGroup>
        );
      })}
    </div>
  );
}

export default View;
